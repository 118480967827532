.bubbles {
    position: absolute;
    top: 110%;
    left: 0;
    width:100vw;
    height:100vh;
    z-index: 0;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

.bubbles li {
    position: absolute;
    list-style: none;
    display: block;
    background-color: rgba(255, 255, 255, 0.15);
    bottom: -100px;
    -webkit-animation: square 20s infinite;
    animation: square 20s infinite;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    border-radius: 50%;
}

.bubbles li:nth-child(1) {
    width: 40px;
    height: 40px;
    left: 20%;
}
.bubbles li:nth-child(2) {
    width: 60px;
    height: 60px;
    left: 40%;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    -webkit-animation-duration: 17s;
    animation-duration: 17s;

}

.bubbles li:nth-child(3) {
    width: 10px;
    height: 10px;
    left: 60%;
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
    -webkit-animation-duration: 13s;
    animation-duration: 13s;
}

.bubbles li:nth-child(4) {
    width: 80px;
    height: 80px;
    left: 70%;

    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-animation-duration: 22s;
    animation-duration: 22s;
}

.bubbles li:nth-child(5) {
    width: 50px;
    height: 50px;
    left: 50%;
    -webkit-animation-delay: 7s;
    animation-delay: 7s;
    -webkit-animation-duration: 12s;
    animation-duration: 12s;
}

@-webkit-keyframes square {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-1080px) rotate(630deg);
        transform: translateY(-1080px) rotate(630deg);
    }
}

@keyframes square {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-1080px) rotate(630deg);
        transform: translateY(-1080px) rotate(630deg);
    }
}

body {
    
    overflow-x:hidden;

}

@media(max-width: 320px){
  body,html {
    overflow-x:hidden;
    width: 320px;
     
  }
}